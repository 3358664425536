<script
  setup
  lang="ts"
  name="FieldSelectDropdown"
  generic="T extends IFieldSelectOption"
>
import { computed, type Ref, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';

import { debounce } from '~/features/useUtilities';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import FieldBaseInput from '~/components/FieldBaseInput.vue';
import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import Icon from '~/components/Icon.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IFieldSelectOption, IFormField } from '~/types';

const props = withDefaults(
  defineProps<{
    modelValue?: string | null;
    field: IFormField<T>;
    options?: T[];
    autofocus?: boolean;
    errors?: string[];
  }>(),
  {
    autofocus: false,
    errors: (): string[] => [],
  },
);

const emit = defineEmits<{
  (e: 'blur'): void;
  (e: 'change'): void;
  (e: 'update:modelValue', value: string): void;
}>();

const label = useVModel(props, 'modelValue', emit);

const hasErrors = computed(() => props.errors.length > 0);

const isSearchable = computed(() => props.field.searchable);

const disabled = computed(() => props.field.disabled);

const options = ref<T[]>([]);

const onSearch = props.field.onSearch;

const optionsAreFiltered = computed(
  () => label.value !== '' && options.value && options.value?.length === 0,
);

const onInput = () => {
  if (onSearch && label.value && label.value.length > 1) {
    debounce(async () => {
      options.value = await onSearch(label.value!);
    })();
    return;
  }

  if (onSearch) {
    options.value = [];
  }
};

const onClick = async (toggle: () => void) => {
  if (onSearch && label.value && label.value.length > 1) {
    options.value = await onSearch(label.value);
    toggle();
  } else {
    toggle();
  }
};

watch(
  () => props.options,
  (newOptions) => {
    options.value = newOptions ?? [];
  },
  { immediate: true },
);
</script>

<template>
  <XDropdown :data-test="`${field.name}-wrapper`">
    <template #trigger="{ toggle, isOpen, close, open }">
      <slot
        name="input"
        :toggle="toggle"
        :close="close"
        :isOpen="isOpen"
        :open="open"
      >
        <FieldBaseInput
          v-model="label"
          class="w-full"
          :name="field.name"
          :label="field.label as string"
          :has-errors="hasErrors"
          :readonly="!isSearchable"
          :disabled="disabled"
          :autofocus="autofocus"
          @click="() => onClick(toggle)"
          @input="onInput"
        >
          <template v-if="!disabled" #append>
            <Icon
              icon="mdi:chevron-right"
              class="pointer-events-none transform transition-transform ease-in"
              :class="{
                '-rotate-90': isOpen,
                'rotate-90': !isOpen,
              }"
              @click="toggle"
            />
          </template>
        </FieldBaseInput>
      </slot>

      <FieldBaseErrors
        v-if="hasErrors"
        :errors="errors"
        class="mt-1"
        :data-test="`${field.name}-error`"
      />
    </template>

    <template #default="{ close, isOpen }">
      <FilterMenuOptions :hasOptions="!!options?.length && !optionsAreFiltered">
        <slot :close="close" :isOpen="isOpen" :options="options" />
      </FilterMenuOptions>
    </template>
  </XDropdown>
</template>
