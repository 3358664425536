<script setup lang="ts" name="Tooltip">
import { ref, useTemplateRef } from 'vue';
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  type OffsetOptions,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';
const {
  description,
  title,
  placement = 'bottom-start',
  offsetOptions = 8,
  variant = 'dark',
  width,
} = defineProps<{
  description: string;
  title?: string;
  width?: number;
  placement?: Placement;
  offsetOptions?: OffsetOptions;
  variant?: 'dark' | 'light';
}>();

const isShown = ref(false);

const triggerRef = ref<Element>();
const tooltipRef = useTemplateRef<HTMLElement>('tooltipRef');
const arrowRef = useTemplateRef<HTMLDivElement>('arrowRef');
const floating = ref<ReturnType<typeof useFloating> | null>(null);

const setTriggerRef = (el: unknown) => {
  triggerRef.value = el as Element;
};

const initFloating = () => {
  floating.value = useFloating(triggerRef, tooltipRef, {
    placement,
    middleware: [
      offset(offsetOptions),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
};

function show() {
  isShown.value = true;

  if (!floating.value) {
    initFloating();
  }
}

function hide() {
  isShown.value = false;
}
</script>

<template>
  <slot name="trigger" :show="show" :hide="hide" :setRef="setTriggerRef" />

  <Teleport to="#tooltipTarget" v-if="isShown">
    <div
      ref="tooltipRef"
      id="tooltip"
      data-testid="tooltip-content"
      class="z-[1000] rounded-lg p-4 text-sm"
      :class="{
        'w-max max-w-sm': !width,
        'bg-charcoal-2 text-charcoal-9': variant === 'light',
        'bg-charcoal-9 text-white': variant === 'dark',
      }"
      :data-floating-placement="floating?.placement"
      :style="[
        floating?.floatingStyles,
        {
          width: `${width}px`,
        },
      ]"
    >
      <p v-if="title" class="mb-2 font-bold">
        {{ title }}
      </p>
      <p>{{ description }}</p>
      <div
        ref="arrowRef"
        id="arrow"
        class="absolute"
        :style="{
          left:
            floating?.middlewareData.arrow?.x != null
              ? `${floating?.middlewareData.arrow.x}px`
              : '',
          top:
            floating?.middlewareData.arrow?.y != null
              ? `${floating?.middlewareData.arrow.y}px`
              : '',
        }"
      ></div>
    </div>
  </Teleport>
</template>
