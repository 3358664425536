<script setup lang="ts" name="FieldNumeric">
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';

import { useNumericField } from '~/features/useNumericField';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';
import Tooltip from '~/components/Tooltip.vue';

const props = withDefaults(
  defineProps<{
    errors?: string[];
    modelValue?: string | number | null;
    hasErrors?: boolean;
    field: {
      label: string;
      name?: string;
      filled?: boolean;
      format?: boolean;
      popover?: boolean;
      disabled?: boolean;
    };
  }>(),
  {
    errors: (): string[] => [],
    hasErrors: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const { numericValue, onInput, onBlur, onFocus } = useNumericField(
  inputValue,
  !!props.field.format,
);

const fieldHasErrors = computed(
  () => props.hasErrors || props.errors.length > 0,
);

const dataTest = computed(() => props.field.name || 'input-field');
</script>

<template>
  <Tooltip
    description="Please do not use a number separator (comma, dot, space or similar) when entering a number. Only number symbols are applicable in this field."
    variant="light"
    placement="bottom-start"
  >
    <template #trigger="{ show, hide, setRef }">
      <label
        :data-test="dataTest"
        :for="field.name"
        :ref="setRef"
        class="flex h-input w-full items-center rounded-t border-b py-1 transition-colors"
        :class="{
          'cursor-text': !field.disabled,
          'cursor-not-allowed text-charcoal-4': field.disabled,
          'border-red-6 text-charcoal-6': fieldHasErrors,
          'border-charcoal-6 text-charcoal-6 focus-within:border-primary-8 focus-within:text-primary-8':
            !fieldHasErrors,
          'bg-white': !field.filled,
          'bg-charcoal-1 px-4': field.filled,
        }"
      >
        <div class="relative flex h-full w-full flex-1 flex-col-reverse">
          <input
            v-bind="$attrs"
            type="numeric"
            class="input-field h-full w-full cursor-text pb-1 text-black placeholder-transparent outline-none"
            v-model="numericValue"
            :id="field.name"
            :placeholder="field.label"
            :name="field.name"
            :disabled="field.disabled"
            :data-test="`${dataTest}-input`"
            @paste.prevent
            @blur="onBlur(hide)"
            @keypress="onInput"
            @focus="onFocus(show)"
          />
          <span class="input-label block origin-bottom-left">
            {{ field.label }}
          </span>
        </div>
        <div v-if="$slots.append" class="mx-2 flex-shrink-0">
          <slot name="append" />
        </div>
      </label>
    </template>
  </Tooltip>
  <FieldBaseErrors v-if="errors.length > 0" class="mt-1" :errors="errors" />
</template>
