import {
  ORG_FEATURES,
  ORG_NAV_ITEMS_FEATURES,
  ORG_STATISTIC_FEATURES,
} from '~/constants';

import type { IOrganisation, IOrgFeature } from '~/types';

export const ALL_STATISTIC_FEATURES: IOrgFeature[] = Object.values(
  ORG_STATISTIC_FEATURES,
);

const TOUR_FEATURES = [
  ...Object.values(ORG_NAV_ITEMS_FEATURES),
  ORG_FEATURES.STATISTICS_AVAILABLE,
] as const;

const getStatisticAreEnabled = ({ features }: IOrganisation) =>
  features.some((feature) => ALL_STATISTIC_FEATURES.includes(feature));

export const featureIsEnabled = (
  feature: IOrgFeature,
  organisation?: IOrganisation | null,
): boolean => {
  if (!organisation?.features) {
    return false;
  }

  if (feature === ORG_FEATURES.STATISTICS_AVAILABLE) {
    return getStatisticAreEnabled(organisation);
  }

  const featureIsEnabled = organisation.features.includes(feature);

  return featureIsEnabled;
};

export const checkOrganisationHasTourFeatures = (
  organisation?: IOrganisation,
): boolean =>
  TOUR_FEATURES.every((feature) => featureIsEnabled(feature, organisation)) &&
  organisation.kpisCount > 0;
