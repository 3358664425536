import type {
  RouteLocationNamedRaw,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { useRoute } from 'vue-router';

interface RouteFrom extends RouteLocationNormalizedLoaded {
  meta: {
    from?: RouteLocationNamedRaw;
  };
}

export const useRouteBack = (route: RouteLocationNamedRaw) => {
  const currentRoute = useRoute() as RouteFrom;

  const from = currentRoute?.meta?.from;

  if (from?.name === route.name) {
    return from as RouteLocationNamedRaw;
  }

  return route;
};
