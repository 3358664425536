import { computed, ref, toRefs } from 'vue';
import { defineStore } from 'pinia';

import { PINIA_STORE } from '~/constants';

import {
  formatBookingAllocationsRow,
  formatVesselRows,
  useCargoBookingBaseStore,
} from '~/features/cargoBooking';
import {
  getBookingUpdatePayload,
  storeVessels,
  updateVessel,
} from '~/features/cargoBooking/create';
import { cargoBookingsNominateUpdate } from '~/features/cargoBooking/create/cargoBookingsNominateUpdate';
import { formatNumber } from '~/features/useNumbers';
import { storage } from '~/features/useStorage';
import { isEmpty } from '~/features/useUtilities';

import type {
  CargoBookingPort,
  CargoNominateByPortWidgets,
  CargoNominateWidgets,
  ICargoNominatedVesselResponse,
  ICargoNominatedVesselRow,
  ICargoNominateUpdateResponse,
  ICargoNominateUploadEventPayload,
  ICargoNominationAllocations,
  ICargoNominationAllocationsRow,
  IOption,
  NominationListUpdate,
  NominationTradelaneFilterSelections,
  NominationVesselMessage,
} from '~/types';

export const useCargoBookingNominateStore = defineStore(
  PINIA_STORE.CARGO_BOOKING_NOMINATE,
  () => {
    const baseStore = useCargoBookingBaseStore();

    const vessels = ref<ICargoNominatedVesselRow[] | null>(null);
    const nominationMessages = ref<NominationVesselMessage[]>([]);

    const selectedVessel = ref<ICargoNominatedVesselRow | null>(null);

    const widgets = ref<CargoNominateWidgets>({
      totalNominated: null,
      totalDeclared: null,
      toBeNominated: null,
    });

    const portWidgets = ref<CargoNominateByPortWidgets[]>([]);
    const lastNominated = ref('');

    const nominationTradelaneFilter = ref<NominationTradelaneFilterSelections>(
      storage.getNominationTradelaneFilter() ?? {},
    );

    const nominationAllocations = ref<ICargoNominationAllocationsRow[] | null>(
      null,
    );

    const getMaxPorts = <T extends { dischargePorts: CargoBookingPort[] }>(
      registriesWithPorts: T[],
    ) => {
      return Math.max(
        ...registriesWithPorts.map(
          (registry) => registry.dischargePorts.length,
        ),
      );
    };

    const findById = (vesselId: string) =>
      vessels.value?.find((v) => v.id === vesselId);

    const setWidgetsData = ({
      totalNominated,
      totalDeclared,
      toBeNominated,
    }: CargoNominateWidgets) => {
      widgets.value.totalNominated = formatNumber(totalNominated);
      widgets.value.totalDeclared = formatNumber(totalDeclared);
      widgets.value.toBeNominated = formatNumber(toBeNominated);
    };

    const getTradelaneFilter = (
      organisationId: OrganisationId,
      userId: number,
    ): IOption | null =>
      nominationTradelaneFilter.value?.[userId]?.[organisationId] ??
      storage.getNominationTradelaneFilter()?.[userId]?.[organisationId];

    const setTradelaneFilter = (
      tradelane: IOption,
      organisationId: OrganisationId,
      userId: number,
    ) => {
      nominationTradelaneFilter.value[userId] = {
        ...nominationTradelaneFilter.value[userId],
        [organisationId]: tradelane,
      };
      storage.setNominationTradelaneFilter(nominationTradelaneFilter.value);
    };

    const clearTradelaneFilter = (
      organisationId: OrganisationId,
      userId: number,
    ) => {
      delete nominationTradelaneFilter.value?.[userId][organisationId];
      storage.setNominationTradelaneFilter(nominationTradelaneFilter.value);
    };

    const setNominateData = (response: ICargoNominateUploadEventPayload) => {
      if (isEmpty(response)) {
        return;
      }

      const { data, settings, importId, ...widgetsData } = response;

      const maxPorts = getMaxPorts(data) || 1;

      baseStore.setTotalDischargePorts(maxPorts);
      baseStore.setSettings(settings);
      baseStore.setImportId(importId);

      vessels.value = formatVesselRows(data, maxPorts);

      setWidgetsData(widgetsData);
    };

    const setNominatedVessels = (response: ICargoNominatedVesselResponse) => {
      const {
        data,
        byLoadingPort,
        totalNominated,
        totalDeclared,
        lastNominatedAt,
      } = response;
      const maxPorts = getMaxPorts(data);

      baseStore.setTotalDischargePorts(maxPorts);
      portWidgets.value = [
        {
          name: 'byTrade',
          nominated: totalNominated,
          declared: totalDeclared,
        },
        ...byLoadingPort,
      ];
      vessels.value = formatVesselRows(data, maxPorts);
      lastNominated.value = lastNominatedAt;
    };

    const setNominationMessages = (data: NominationVesselMessage[]) => {
      nominationMessages.value = data;
    };

    const addNominationMessage = (message: NominationVesselMessage) => {
      nominationMessages.value.push(message);
    };

    const clearUnreadMessages = (vesselId: string | null) => {
      if (!vesselId) {
        return;
      }

      if (selectedVessel.value?.id === vesselId) {
        selectedVessel.value.unreadMessages = 0;
      }

      vessels.value =
        vessels.value?.map((vessel) => {
          if (vessel.id === vesselId) {
            return {
              ...vessel,
              unreadMessages: 0,
            };
          }
          return vessel;
        }) ?? null;
    };

    const updateNominatedVessels = (data: NominationListUpdate) => {
      vessels.value =
        vessels.value?.map((vessel) => {
          const id = vessel.cargoNominationId ?? vessel.id;
          if (id === data.id) {
            const selectedVesselId =
              selectedVessel.value?.cargoNominationId ??
              selectedVessel.value?.id;
            return {
              ...vessel,
              status: data.status,
              unreadMessages:
                selectedVesselId === data.id
                  ? vessel.unreadMessages
                  : (vessel.unreadMessages ?? 0) + 1,
            };
          }
          return vessel;
        }) ?? null;

      if (selectedVessel.value?.id) {
        selectedVessel.value =
          vessels.value?.find(({ id }) => id === selectedVessel.value?.id) ??
          null;
      }
    };

    const setNominationAllocations = (data: ICargoNominationAllocations[]) => {
      const maxPorts = getMaxPorts(data);
      nominationAllocations.value = formatBookingAllocationsRow(data, maxPorts);
    };

    const isRowSelected = (id: string) => selectedVessel.value?.id === id;

    const selectVessel = (vessel: ICargoNominatedVesselRow) => {
      selectedVessel.value = vessel;
    };

    const unselectVessel = () => {
      selectedVessel.value = null;
    };

    const resetStore = () => {
      vessels.value = null;
      selectedVessel.value = null;
      nominationAllocations.value = null;
      baseStore.reset();
      portWidgets.value = [];
      nominationMessages.value = [];
      lastNominated.value = '';
    };

    const updateVessels = async (
      orgId: OrganisationId,
      vessel: ICargoNominatedVesselRow,
    ) => {
      if (!vessels.value) {
        return false;
      }

      const updatedVessel = getBookingUpdatePayload({
        booking: vessel,
        importId: baseStore.state.importId,
      });

      baseStore.setIsUpdating(true);
      try {
        const response: ICargoNominateUpdateResponse | null =
          await updateVessel(orgId, updatedVessel);

        if (!response) {
          return false;
        }

        const { data, ...widgetData }: ICargoNominateUpdateResponse = response;

        const newVessels = formatVesselRows(
          data,
          baseStore.state.totalDischargePorts,
        );

        cargoBookingsNominateUpdate(vessels.value, newVessels);

        setWidgetsData(widgetData);

        if (selectedVessel.value) {
          const updatedSelectedBooking = vessels.value.find(
            (vessel) => selectedVessel.value?.id === vessel.id,
          );

          selectedVessel.value = updatedSelectedBooking ?? null;
        }
        return true;
      } catch {
        return false;
      } finally {
        baseStore.setIsUpdating(false);
      }
    };

    const totalErrors = computed(() => {
      return baseStore.calculateValidationErrors(vessels.value);
    });

    const submitNomination = (orgId: OrganisationId) => {
      if (!baseStore.state.importId) {
        return;
      }

      return storeVessels(orgId, baseStore.state.importId);
    };

    const setApprovedVessel = (id: string) => {
      const vessel = vessels.value?.find((v) => v.id === id);
      if (vessel) {
        vessel.approved = true;
      }
    };

    return {
      vessels,
      findById,
      selectedVessel,
      widgets,
      portWidgets,
      nominationAllocations,
      nominationMessages,
      totalErrors,
      nominationTradelaneFilter,
      lastNominated,
      ...toRefs(baseStore.state),
      resetStore,
      selectVessel,
      unselectVessel,
      setNominateData,
      setNominatedVessels,
      updateNominatedVessels,
      setNominationAllocations,
      setNominationMessages,
      addNominationMessage,
      clearUnreadMessages,
      getTradelaneFilter,
      setTradelaneFilter,
      clearTradelaneFilter,
      isRowSelected,
      updateVessels,
      submitNomination,
      setApprovedVessel,
    };
  },
);
