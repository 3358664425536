import { computed, isProxy, isRef } from 'vue';

import { isEmpty, isObject, toTitleCase } from '~/features/useUtilities';

import { formatDateRange } from './useDates';

const TYPE_TO_LABEL = {
  loadingPorts: (value: string) => `Load: ${value}`,
  dischargePorts: (value: string) => `Discharge: ${value}`,
  tradelane: (value: string) => `Trade: ${value}`,
  vessels: (value: string) => `Vessel: ${value}`,
  nominationDate: (value: string) => `Date: ${value}`,
  status: (value: string) => `Status: ${toTitleCase(value)}`,
};

const getObjectLabel = (filter) => {
  const isCustomRange = !!filter.cp_start;
  if (isCustomRange) {
    return formatDateRange(filter.cp_start, filter.cp_end);
  }

  return filter.label ? filter.label : filter.name;
};

const getLabel = (filter, type) => {
  const isPrimitive = typeof filter === 'string' || typeof filter === 'number';
  const labelValue = isPrimitive ? filter : getObjectLabel(filter);
  const label = TYPE_TO_LABEL[type]?.(labelValue) || labelValue;

  return label;
};

const getFilter = (key) => (filter) => ({
  type: key,
  id: filter.id || filter,
  label: getLabel(filter, key),
});

const isDefaultFilter = (filter, defaultValue) =>
  !isEmpty(defaultValue) && filter.id === defaultValue.id;

const getActiveFilter = (filters, defaultValue) => (key) => {
  const filter = filters[key];
  if (isEmpty(filter)) {
    return null;
  }

  if (isDefaultFilter(filter, defaultValue)) {
    return null;
  }

  return isObject(filter)
    ? getFilter(key)(filter)
    : filter?.map(getFilter(key) ?? []);
};

const getActiveFilters = (filters, defaultValue) => {
  const filterKeys = Object.keys(filters);
  if (filterKeys.every((key) => isEmpty(filters[key]))) {
    return [];
  }

  return filterKeys
    .flatMap(getActiveFilter(filters, defaultValue))
    .filter((x) => !isEmpty(x));
};

const removeActiveObjectFilter = (defaultValue) => {
  if (isEmpty(defaultValue)) {
    return {};
  }

  return defaultValue;
};

export const useActiveFilters = (filters, defaultValue = {}) => {
  const activeFilters = computed(() =>
    getActiveFilters(isProxy(filters) ? filters : filters.value, defaultValue),
  );

  const clearFilter = (filter, query) => {
    const newQuery = isRef(query) ? { ...query.value } : { ...query };

    if (isObject(newQuery[filter.type])) {
      newQuery[filter.type] = removeActiveObjectFilter(defaultValue);
    } else {
      newQuery[filter.type] = newQuery[filter.type].filter((active) =>
        active.id ? active.id !== filter.id : active !== filter.id,
      );
    }

    return newQuery;
  };

  const clearQuery = (query) => {
    const newQuery = isRef(query) ? { ...query.value } : { ...query };

    Object.keys(newQuery).forEach((key) => {
      if (isObject(newQuery[key])) {
        newQuery[key] = removeActiveObjectFilter(defaultValue);
        return;
      }

      newQuery[key] = [];
    });

    return newQuery;
  };

  return { activeFilters, clearFilter, clearQuery };
};
