<script setup lang="ts" name="XDropdown">
import { nextTick, ref, Teleport, useTemplateRef, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import {
  autoUpdate,
  flip,
  offset,
  type Placement,
  shift,
  size,
  useFloating,
} from '@floating-ui/vue';

const {
  teleport = true,
  parentRef,
  placement = 'bottom-start',
} = defineProps<{
  teleport?: boolean;
  offset?: [number, number];
  parentRef?: HTMLElement;
  placement?: Placement;
}>();

const emit = defineEmits(['close', 'open', 'clickOutside']);

const isOpen = ref(false);
const targetRef = useTemplateRef('targetRef');
const contentRef = useTemplateRef('contentRef');
const triggerRef = useTemplateRef('triggerRef');

onClickOutside(
  contentRef,
  () => {
    isOpen.value = false;
    emit('clickOutside');
  },
  { ignore: [targetRef] },
);

const close = () => {
  if (!isOpen.value) return;

  isOpen.value = false;
  emit('close');
};

const open = () => {
  if (isOpen.value) return;

  isOpen.value = true;
  emit('open');

  initializeFloating();
};

const toggle = () => (isOpen.value ? close() : open());

const floating = ref<ReturnType<typeof useFloating> | null>(null);

const initializeFloating = () => {
  if (floating.value) return;

  floating.value = useFloating(triggerRef || parentRef, contentRef, {
    placement,
    middleware: [
      offset(5),
      flip(),
      shift(),
      size({
        apply({ elements, rects }) {
          elements.floating.style.minWidth = `${rects.reference.width}px`;
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
};

watch(
  () => isOpen.value,
  (opened) => {
    if (!opened) return;

    nextTick(() => {
      floating.value?.update();
    });
  },
  {
    immediate: true,
  },
);

defineExpose({
  open,
  close,
});
</script>

<template>
  <div ref="targetRef" class="relative">
    <div ref="triggerRef">
      <slot
        name="trigger"
        :toggle="toggle"
        :is-open="isOpen"
        :open="open"
        :close="close"
      />
    </div>
    <Teleport v-if="teleport" to="#dropdown" :disabled="!isOpen">
      <div
        v-if="isOpen"
        ref="contentRef"
        :style="floating?.floatingStyles"
        class="z-50 overflow-hidden rounded bg-white shadow-md"
      >
        <slot :close="close" :open="open" :is-open="isOpen" />
      </div>
    </Teleport>
    <template v-else>
      <div
        v-if="isOpen"
        ref="contentRef"
        :style="floating?.floatingStyles"
        class="z-50 overflow-hidden rounded bg-white shadow-md"
      >
        <slot :close="close" :open="open" :is-open="isOpen" />
      </div>
    </template>
  </div>
</template>
