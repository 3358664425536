<script lang="ts" setup name="InfoTooltip">
import { type Placement } from '@floating-ui/vue';

import { useOnResize } from '~/features/useOnResize';

import BaseCollapsible from '~/components/BaseCollapsible.vue';
import Icon from '~/components/Icon.vue';
import Tooltip from '~/components/Tooltip.vue';

const {
  title,
  description,
  placement = 'bottom-start',
  color = 'default',
  width,
  preventFocus,
  isResponsive = false,
} = defineProps<{
  title?: string;
  width?: number;
  preventFocus?: boolean;
  color?: 'danger' | 'warning' | 'default';
  description: string;
  placement?: Placement;
  disabled?: boolean;
  isResponsive?: boolean;
}>();

const emit = defineEmits<{
  onClick: [];
  onDoubleClick: [];
}>();

const handleMouseDown = (event: MouseEvent) => {
  if (preventFocus) {
    event.preventDefault();
  }
};

const { isOnMobile } = useOnResize();
</script>

<template>
  <Tooltip
    v-if="!isOnMobile || (isOnMobile && !isResponsive)"
    :title="title"
    :width="width"
    :description="description"
    :placement="placement"
  >
    <template #trigger="{ show, hide, setRef }">
      <button
        :ref="setRef"
        type="button"
        data-testid="tooltip-trigger"
        class="flex-center"
        :tabindex="preventFocus ? '-1' : undefined"
        :class="{
          'text-danger-6 hover:text-danger-7': color === 'danger',
          'text-warning-8 hover:text-warning-10': color === 'warning',
          'cursor-not-allowed text-charcoal-4': disabled,
          'text-indigo-4 hover:text-primary-6':
            color === 'default' && !disabled,
        }"
        :disabled="disabled"
        @mouseover="show"
        @mouseleave="hide"
        @click.stop="emit('onClick')"
        @dblclick.stop="emit('onDoubleClick')"
        @mousedown.prevent="handleMouseDown"
      >
        <Icon icon="mdi:information" />
      </button>
    </template>
  </Tooltip>

  <BaseCollapsible
    v-else
    data-testid="info-tooltip-baseCollapsible"
    class="w-full"
    :openByDefault="false"
  >
    <template #trigger="{ toggle }">
      <div class="flex w-full justify-between">
        <slot />

        <button
          type="button"
          data-testid="collapsible-trigger"
          class="flex items-start"
          :tabindex="preventFocus ? '-1' : undefined"
          :class="{
            'text-danger-6 hover:text-danger-7': color === 'danger',
            'text-warning-8 hover:text-warning-10': color === 'warning',
            'cursor-not-allowed text-charcoal-4': disabled,
            'text-indigo-4 hover:text-primary-6':
              color === 'default' && !disabled,
          }"
          :disabled="disabled"
          @click="toggle"
        >
          <Icon icon="mdi:information" />
        </button>
      </div>
    </template>
    <template #default>
      <div
        class="my-2 border-b border-t border-charcoal-2 bg-charcoal-1 p-2 text-body-2"
      >
        <p class="font-bold">
          {{ title }}
        </p>
        <p class="mb-2">
          {{ description }}
        </p>
      </div>
    </template>
  </BaseCollapsible>
</template>
