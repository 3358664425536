import type {
  IDataTableBaseCell,
  IDataTableBaseRow,
  IDataTableCell,
  IDataTableError,
} from '~/types';

export const checkEditableCell = (
  cell: IDataTableBaseCell,
  editable?: string[],
) => {
  const key = cell.dynamic ? cell.key.split('.')[0] : cell.key;

  return editable?.includes(key) ?? false;
};

export const isDynamicCell = <T extends IDataTableCell | IDataTableBaseCell>(
  cell: T,
): cell is T & { dynamic: string } => !!cell.dynamic;

export const getDynamicCellValue = <T extends IDataTableBaseRow>(
  cell: IDataTableBaseCell & { dynamic: string },
  item: T,
) => {
  const dynamicParts = cell.dynamic.split('.');
  const parentObject = dynamicParts
    .slice(0, -1)
    .reduce((obj, part) => obj && obj[part], item);
  return cell.transform ? cell.transform(parentObject) : parentObject;
};

export const isNestedKeyCell = (
  cell: IDataTableCell | IDataTableBaseCell,
): boolean => cell.key?.includes('.');

export const getNestedKeyValue = <T extends IDataTableBaseRow>(
  cell: IDataTableBaseCell,
  item: T,
) => {
  const [key, nestedKey] = cell.key.split('.');

  return cell.type === 'options' ? item[key] : item[key]?.[nestedKey];
};

export const getCellError = (
  errors: IDataTableError[] | undefined,
  cell: IDataTableBaseCell,
) => {
  if (!errors) {
    return null;
  }

  const cellAttribute = cell.dynamic ?? cell.key;

  return errors.find((error) => error.attribute === cellAttribute);
};
